import { defineStore } from 'pinia'

export type IWidgetParameters = { [propKey: string]: string }

export interface IWidgetState {
  images: any
  partnerId: number | null
  parameters: IWidgetParameters
  localizations: any
}

export const useWidgetOptions = defineStore('widget', {
  state: (): IWidgetState => ({
    images: {},
    partnerId: null,
    parameters: {},
    localizations: {}
  }),
  actions: {
    setImages(images: any) {
      this.images = images
    },
    setPartnerId(partnerId: number) {
      this.partnerId = partnerId
    },
    setParameters(parameters: IWidgetParameters) {
      this.parameters = parameters
    },
    setLocalizations(localizations: any) {
      this.localizations = localizations
    }
  },
  getters: {
    getLogo: state => () => state.images.mainLogo ?? null,
    hasLogo: state => () => state.images.hasOwnProperty('mainLogo'),
    getImage: state => (key: string) => state.images[key] ?? null,
    hasImage: state => (key: string) => state.images.hasOwnProperty(key),
    getParameters: state => () => state.parameters,
    getParameter: state => (key: string) => state.parameters[key] ?? undefined,
    getBooleanParameter: state => (key: string) =>
      !!JSON.parse(String(state.parameters[key] ?? false).toLowerCase()),
    getPartnerId: state => () => state.partnerId,
    getLocalizations: state => () => state.localizations
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWidgetOptions, import.meta.hot))
}
