import { default as indexJ8HTqRL1KJMeta } from "/opt/mc/app/pages/index.vue?macro=true";
import { default as indexFngy3HRLfAMeta } from "/opt/mc/app/pages/widget/baasSimManagementLite/index.vue?macro=true";
import { default as loginfKMeZLSXzxMeta } from "/opt/mc/app/pages/widget/baasSimManagementLite/login.vue?macro=true";
import { default as loginByTyper4JWDnvkfQMeta } from "/opt/mc/app/pages/widget/baasSimManagementLite/loginByType.vue?macro=true";
import { default as orderV2Y4ZTDGl4Meta } from "/opt/mc/app/pages/widget/baasSimManagementLite/order.vue?macro=true";
import { default as sim0r88wuq17kMeta } from "/opt/mc/app/pages/widget/baasSimManagementLite/sim.vue?macro=true";
import { default as baasSimManagementLitem4GahHmMsdMeta } from "/opt/mc/app/pages/widget/baasSimManagementLite.vue?macro=true";
import { default as acceptProposalOgfayWGhwxMeta } from "/opt/mc/app/pages/widget/proposal/acceptProposal.vue?macro=true";
import { default as indexLL8b2s6qwWMeta } from "/opt/mc/app/pages/widget/proposal/index.vue?macro=true";
import { default as proposaliMkQsjSeIVMeta } from "/opt/mc/app/pages/widget/proposal/proposal.vue?macro=true";
import { default as refuseProposalPdWMiRiiEJMeta } from "/opt/mc/app/pages/widget/proposal/refuseProposal.vue?macro=true";
import { default as proposalI8TsL9l3kFMeta } from "/opt/mc/app/pages/widget/proposal.vue?macro=true";
import { default as widget8U5j5NGATxMeta } from "/opt/mc/app/pages/widget.vue?macro=true";
export default [
  {
    name: indexJ8HTqRL1KJMeta?.name ?? "index___en",
    path: indexJ8HTqRL1KJMeta?.path ?? "/",
    meta: indexJ8HTqRL1KJMeta || {},
    alias: indexJ8HTqRL1KJMeta?.alias || [],
    redirect: indexJ8HTqRL1KJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ8HTqRL1KJMeta?.name ?? "index___de",
    path: indexJ8HTqRL1KJMeta?.path ?? "/de",
    meta: indexJ8HTqRL1KJMeta || {},
    alias: indexJ8HTqRL1KJMeta?.alias || [],
    redirect: indexJ8HTqRL1KJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ8HTqRL1KJMeta?.name ?? "index___cs",
    path: indexJ8HTqRL1KJMeta?.path ?? "/cs",
    meta: indexJ8HTqRL1KJMeta || {},
    alias: indexJ8HTqRL1KJMeta?.alias || [],
    redirect: indexJ8HTqRL1KJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ8HTqRL1KJMeta?.name ?? "index___sk",
    path: indexJ8HTqRL1KJMeta?.path ?? "/sk",
    meta: indexJ8HTqRL1KJMeta || {},
    alias: indexJ8HTqRL1KJMeta?.alias || [],
    redirect: indexJ8HTqRL1KJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ8HTqRL1KJMeta?.name ?? "index___pl",
    path: indexJ8HTqRL1KJMeta?.path ?? "/pl",
    meta: indexJ8HTqRL1KJMeta || {},
    alias: indexJ8HTqRL1KJMeta?.alias || [],
    redirect: indexJ8HTqRL1KJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: widget8U5j5NGATxMeta?.name ?? "widget___en",
    path: widget8U5j5NGATxMeta?.path ?? "/widget",
    children: [
  {
    path: baasSimManagementLitem4GahHmMsdMeta?.path ?? "baasSimManagementLite",
    children: [
  {
    name: indexFngy3HRLfAMeta?.name ?? "widget-baasSimManagementLite___en",
    path: indexFngy3HRLfAMeta?.path ?? "",
    meta: indexFngy3HRLfAMeta || {},
    alias: indexFngy3HRLfAMeta?.alias || [],
    redirect: indexFngy3HRLfAMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/index.vue").then(m => m.default || m)
  },
  {
    name: loginfKMeZLSXzxMeta?.name ?? "widget-baasSimManagementLite-login___en",
    path: loginfKMeZLSXzxMeta?.path ?? "login",
    meta: loginfKMeZLSXzxMeta || {},
    alias: loginfKMeZLSXzxMeta?.alias || [],
    redirect: loginfKMeZLSXzxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/login.vue").then(m => m.default || m)
  },
  {
    name: loginByTyper4JWDnvkfQMeta?.name ?? "widget-baasSimManagementLite-loginByType___en",
    path: loginByTyper4JWDnvkfQMeta?.path ?? "loginByType",
    meta: loginByTyper4JWDnvkfQMeta || {},
    alias: loginByTyper4JWDnvkfQMeta?.alias || [],
    redirect: loginByTyper4JWDnvkfQMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/loginByType.vue").then(m => m.default || m)
  },
  {
    name: orderV2Y4ZTDGl4Meta?.name ?? "widget-baasSimManagementLite-order___en",
    path: orderV2Y4ZTDGl4Meta?.path ?? "order",
    meta: orderV2Y4ZTDGl4Meta || {},
    alias: orderV2Y4ZTDGl4Meta?.alias || [],
    redirect: orderV2Y4ZTDGl4Meta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/order.vue").then(m => m.default || m)
  },
  {
    name: sim0r88wuq17kMeta?.name ?? "widget-baasSimManagementLite-sim___en",
    path: sim0r88wuq17kMeta?.path ?? "sim",
    meta: sim0r88wuq17kMeta || {},
    alias: sim0r88wuq17kMeta?.alias || [],
    redirect: sim0r88wuq17kMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/sim.vue").then(m => m.default || m)
  }
],
    name: baasSimManagementLitem4GahHmMsdMeta?.name ?? undefined,
    meta: baasSimManagementLitem4GahHmMsdMeta || {},
    alias: baasSimManagementLitem4GahHmMsdMeta?.alias || [],
    redirect: baasSimManagementLitem4GahHmMsdMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite.vue").then(m => m.default || m)
  },
  {
    path: proposalI8TsL9l3kFMeta?.path ?? "proposal",
    children: [
  {
    name: acceptProposalOgfayWGhwxMeta?.name ?? "widget-proposal-acceptProposal___en",
    path: acceptProposalOgfayWGhwxMeta?.path ?? "acceptProposal",
    meta: acceptProposalOgfayWGhwxMeta || {},
    alias: acceptProposalOgfayWGhwxMeta?.alias || [],
    redirect: acceptProposalOgfayWGhwxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/acceptProposal.vue").then(m => m.default || m)
  },
  {
    name: indexLL8b2s6qwWMeta?.name ?? "widget-proposal___en",
    path: indexLL8b2s6qwWMeta?.path ?? "",
    meta: indexLL8b2s6qwWMeta || {},
    alias: indexLL8b2s6qwWMeta?.alias || [],
    redirect: indexLL8b2s6qwWMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/index.vue").then(m => m.default || m)
  },
  {
    name: proposaliMkQsjSeIVMeta?.name ?? "widget-proposal-proposal___en",
    path: proposaliMkQsjSeIVMeta?.path ?? "proposal",
    meta: proposaliMkQsjSeIVMeta || {},
    alias: proposaliMkQsjSeIVMeta?.alias || [],
    redirect: proposaliMkQsjSeIVMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/proposal.vue").then(m => m.default || m)
  },
  {
    name: refuseProposalPdWMiRiiEJMeta?.name ?? "widget-proposal-refuseProposal___en",
    path: refuseProposalPdWMiRiiEJMeta?.path ?? "refuseProposal",
    meta: refuseProposalPdWMiRiiEJMeta || {},
    alias: refuseProposalPdWMiRiiEJMeta?.alias || [],
    redirect: refuseProposalPdWMiRiiEJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/refuseProposal.vue").then(m => m.default || m)
  }
],
    name: proposalI8TsL9l3kFMeta?.name ?? undefined,
    meta: proposalI8TsL9l3kFMeta || {},
    alias: proposalI8TsL9l3kFMeta?.alias || [],
    redirect: proposalI8TsL9l3kFMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal.vue").then(m => m.default || m)
  }
],
    meta: widget8U5j5NGATxMeta || {},
    alias: widget8U5j5NGATxMeta?.alias || [],
    redirect: widget8U5j5NGATxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget8U5j5NGATxMeta?.name ?? "widget___de",
    path: widget8U5j5NGATxMeta?.path ?? "/de/widget",
    children: [
  {
    path: baasSimManagementLitem4GahHmMsdMeta?.path ?? "baasSimManagementLite",
    children: [
  {
    name: indexFngy3HRLfAMeta?.name ?? "widget-baasSimManagementLite___de",
    path: indexFngy3HRLfAMeta?.path ?? "",
    meta: indexFngy3HRLfAMeta || {},
    alias: indexFngy3HRLfAMeta?.alias || [],
    redirect: indexFngy3HRLfAMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/index.vue").then(m => m.default || m)
  },
  {
    name: loginfKMeZLSXzxMeta?.name ?? "widget-baasSimManagementLite-login___de",
    path: loginfKMeZLSXzxMeta?.path ?? "login",
    meta: loginfKMeZLSXzxMeta || {},
    alias: loginfKMeZLSXzxMeta?.alias || [],
    redirect: loginfKMeZLSXzxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/login.vue").then(m => m.default || m)
  },
  {
    name: loginByTyper4JWDnvkfQMeta?.name ?? "widget-baasSimManagementLite-loginByType___de",
    path: loginByTyper4JWDnvkfQMeta?.path ?? "loginByType",
    meta: loginByTyper4JWDnvkfQMeta || {},
    alias: loginByTyper4JWDnvkfQMeta?.alias || [],
    redirect: loginByTyper4JWDnvkfQMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/loginByType.vue").then(m => m.default || m)
  },
  {
    name: orderV2Y4ZTDGl4Meta?.name ?? "widget-baasSimManagementLite-order___de",
    path: orderV2Y4ZTDGl4Meta?.path ?? "order",
    meta: orderV2Y4ZTDGl4Meta || {},
    alias: orderV2Y4ZTDGl4Meta?.alias || [],
    redirect: orderV2Y4ZTDGl4Meta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/order.vue").then(m => m.default || m)
  },
  {
    name: sim0r88wuq17kMeta?.name ?? "widget-baasSimManagementLite-sim___de",
    path: sim0r88wuq17kMeta?.path ?? "sim",
    meta: sim0r88wuq17kMeta || {},
    alias: sim0r88wuq17kMeta?.alias || [],
    redirect: sim0r88wuq17kMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/sim.vue").then(m => m.default || m)
  }
],
    name: baasSimManagementLitem4GahHmMsdMeta?.name ?? undefined,
    meta: baasSimManagementLitem4GahHmMsdMeta || {},
    alias: baasSimManagementLitem4GahHmMsdMeta?.alias || [],
    redirect: baasSimManagementLitem4GahHmMsdMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite.vue").then(m => m.default || m)
  },
  {
    path: proposalI8TsL9l3kFMeta?.path ?? "proposal",
    children: [
  {
    name: acceptProposalOgfayWGhwxMeta?.name ?? "widget-proposal-acceptProposal___de",
    path: acceptProposalOgfayWGhwxMeta?.path ?? "acceptProposal",
    meta: acceptProposalOgfayWGhwxMeta || {},
    alias: acceptProposalOgfayWGhwxMeta?.alias || [],
    redirect: acceptProposalOgfayWGhwxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/acceptProposal.vue").then(m => m.default || m)
  },
  {
    name: indexLL8b2s6qwWMeta?.name ?? "widget-proposal___de",
    path: indexLL8b2s6qwWMeta?.path ?? "",
    meta: indexLL8b2s6qwWMeta || {},
    alias: indexLL8b2s6qwWMeta?.alias || [],
    redirect: indexLL8b2s6qwWMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/index.vue").then(m => m.default || m)
  },
  {
    name: proposaliMkQsjSeIVMeta?.name ?? "widget-proposal-proposal___de",
    path: proposaliMkQsjSeIVMeta?.path ?? "proposal",
    meta: proposaliMkQsjSeIVMeta || {},
    alias: proposaliMkQsjSeIVMeta?.alias || [],
    redirect: proposaliMkQsjSeIVMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/proposal.vue").then(m => m.default || m)
  },
  {
    name: refuseProposalPdWMiRiiEJMeta?.name ?? "widget-proposal-refuseProposal___de",
    path: refuseProposalPdWMiRiiEJMeta?.path ?? "refuseProposal",
    meta: refuseProposalPdWMiRiiEJMeta || {},
    alias: refuseProposalPdWMiRiiEJMeta?.alias || [],
    redirect: refuseProposalPdWMiRiiEJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/refuseProposal.vue").then(m => m.default || m)
  }
],
    name: proposalI8TsL9l3kFMeta?.name ?? undefined,
    meta: proposalI8TsL9l3kFMeta || {},
    alias: proposalI8TsL9l3kFMeta?.alias || [],
    redirect: proposalI8TsL9l3kFMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal.vue").then(m => m.default || m)
  }
],
    meta: widget8U5j5NGATxMeta || {},
    alias: widget8U5j5NGATxMeta?.alias || [],
    redirect: widget8U5j5NGATxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget8U5j5NGATxMeta?.name ?? "widget___cs",
    path: widget8U5j5NGATxMeta?.path ?? "/cs/widget",
    children: [
  {
    path: baasSimManagementLitem4GahHmMsdMeta?.path ?? "baasSimManagementLite",
    children: [
  {
    name: indexFngy3HRLfAMeta?.name ?? "widget-baasSimManagementLite___cs",
    path: indexFngy3HRLfAMeta?.path ?? "",
    meta: indexFngy3HRLfAMeta || {},
    alias: indexFngy3HRLfAMeta?.alias || [],
    redirect: indexFngy3HRLfAMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/index.vue").then(m => m.default || m)
  },
  {
    name: loginfKMeZLSXzxMeta?.name ?? "widget-baasSimManagementLite-login___cs",
    path: loginfKMeZLSXzxMeta?.path ?? "login",
    meta: loginfKMeZLSXzxMeta || {},
    alias: loginfKMeZLSXzxMeta?.alias || [],
    redirect: loginfKMeZLSXzxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/login.vue").then(m => m.default || m)
  },
  {
    name: loginByTyper4JWDnvkfQMeta?.name ?? "widget-baasSimManagementLite-loginByType___cs",
    path: loginByTyper4JWDnvkfQMeta?.path ?? "loginByType",
    meta: loginByTyper4JWDnvkfQMeta || {},
    alias: loginByTyper4JWDnvkfQMeta?.alias || [],
    redirect: loginByTyper4JWDnvkfQMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/loginByType.vue").then(m => m.default || m)
  },
  {
    name: orderV2Y4ZTDGl4Meta?.name ?? "widget-baasSimManagementLite-order___cs",
    path: orderV2Y4ZTDGl4Meta?.path ?? "order",
    meta: orderV2Y4ZTDGl4Meta || {},
    alias: orderV2Y4ZTDGl4Meta?.alias || [],
    redirect: orderV2Y4ZTDGl4Meta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/order.vue").then(m => m.default || m)
  },
  {
    name: sim0r88wuq17kMeta?.name ?? "widget-baasSimManagementLite-sim___cs",
    path: sim0r88wuq17kMeta?.path ?? "sim",
    meta: sim0r88wuq17kMeta || {},
    alias: sim0r88wuq17kMeta?.alias || [],
    redirect: sim0r88wuq17kMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/sim.vue").then(m => m.default || m)
  }
],
    name: baasSimManagementLitem4GahHmMsdMeta?.name ?? undefined,
    meta: baasSimManagementLitem4GahHmMsdMeta || {},
    alias: baasSimManagementLitem4GahHmMsdMeta?.alias || [],
    redirect: baasSimManagementLitem4GahHmMsdMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite.vue").then(m => m.default || m)
  },
  {
    path: proposalI8TsL9l3kFMeta?.path ?? "proposal",
    children: [
  {
    name: acceptProposalOgfayWGhwxMeta?.name ?? "widget-proposal-acceptProposal___cs",
    path: acceptProposalOgfayWGhwxMeta?.path ?? "acceptProposal",
    meta: acceptProposalOgfayWGhwxMeta || {},
    alias: acceptProposalOgfayWGhwxMeta?.alias || [],
    redirect: acceptProposalOgfayWGhwxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/acceptProposal.vue").then(m => m.default || m)
  },
  {
    name: indexLL8b2s6qwWMeta?.name ?? "widget-proposal___cs",
    path: indexLL8b2s6qwWMeta?.path ?? "",
    meta: indexLL8b2s6qwWMeta || {},
    alias: indexLL8b2s6qwWMeta?.alias || [],
    redirect: indexLL8b2s6qwWMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/index.vue").then(m => m.default || m)
  },
  {
    name: proposaliMkQsjSeIVMeta?.name ?? "widget-proposal-proposal___cs",
    path: proposaliMkQsjSeIVMeta?.path ?? "proposal",
    meta: proposaliMkQsjSeIVMeta || {},
    alias: proposaliMkQsjSeIVMeta?.alias || [],
    redirect: proposaliMkQsjSeIVMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/proposal.vue").then(m => m.default || m)
  },
  {
    name: refuseProposalPdWMiRiiEJMeta?.name ?? "widget-proposal-refuseProposal___cs",
    path: refuseProposalPdWMiRiiEJMeta?.path ?? "refuseProposal",
    meta: refuseProposalPdWMiRiiEJMeta || {},
    alias: refuseProposalPdWMiRiiEJMeta?.alias || [],
    redirect: refuseProposalPdWMiRiiEJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/refuseProposal.vue").then(m => m.default || m)
  }
],
    name: proposalI8TsL9l3kFMeta?.name ?? undefined,
    meta: proposalI8TsL9l3kFMeta || {},
    alias: proposalI8TsL9l3kFMeta?.alias || [],
    redirect: proposalI8TsL9l3kFMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal.vue").then(m => m.default || m)
  }
],
    meta: widget8U5j5NGATxMeta || {},
    alias: widget8U5j5NGATxMeta?.alias || [],
    redirect: widget8U5j5NGATxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget8U5j5NGATxMeta?.name ?? "widget___sk",
    path: widget8U5j5NGATxMeta?.path ?? "/sk/widget",
    children: [
  {
    path: baasSimManagementLitem4GahHmMsdMeta?.path ?? "baasSimManagementLite",
    children: [
  {
    name: indexFngy3HRLfAMeta?.name ?? "widget-baasSimManagementLite___sk",
    path: indexFngy3HRLfAMeta?.path ?? "",
    meta: indexFngy3HRLfAMeta || {},
    alias: indexFngy3HRLfAMeta?.alias || [],
    redirect: indexFngy3HRLfAMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/index.vue").then(m => m.default || m)
  },
  {
    name: loginfKMeZLSXzxMeta?.name ?? "widget-baasSimManagementLite-login___sk",
    path: loginfKMeZLSXzxMeta?.path ?? "login",
    meta: loginfKMeZLSXzxMeta || {},
    alias: loginfKMeZLSXzxMeta?.alias || [],
    redirect: loginfKMeZLSXzxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/login.vue").then(m => m.default || m)
  },
  {
    name: loginByTyper4JWDnvkfQMeta?.name ?? "widget-baasSimManagementLite-loginByType___sk",
    path: loginByTyper4JWDnvkfQMeta?.path ?? "loginByType",
    meta: loginByTyper4JWDnvkfQMeta || {},
    alias: loginByTyper4JWDnvkfQMeta?.alias || [],
    redirect: loginByTyper4JWDnvkfQMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/loginByType.vue").then(m => m.default || m)
  },
  {
    name: orderV2Y4ZTDGl4Meta?.name ?? "widget-baasSimManagementLite-order___sk",
    path: orderV2Y4ZTDGl4Meta?.path ?? "order",
    meta: orderV2Y4ZTDGl4Meta || {},
    alias: orderV2Y4ZTDGl4Meta?.alias || [],
    redirect: orderV2Y4ZTDGl4Meta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/order.vue").then(m => m.default || m)
  },
  {
    name: sim0r88wuq17kMeta?.name ?? "widget-baasSimManagementLite-sim___sk",
    path: sim0r88wuq17kMeta?.path ?? "sim",
    meta: sim0r88wuq17kMeta || {},
    alias: sim0r88wuq17kMeta?.alias || [],
    redirect: sim0r88wuq17kMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/sim.vue").then(m => m.default || m)
  }
],
    name: baasSimManagementLitem4GahHmMsdMeta?.name ?? undefined,
    meta: baasSimManagementLitem4GahHmMsdMeta || {},
    alias: baasSimManagementLitem4GahHmMsdMeta?.alias || [],
    redirect: baasSimManagementLitem4GahHmMsdMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite.vue").then(m => m.default || m)
  },
  {
    path: proposalI8TsL9l3kFMeta?.path ?? "proposal",
    children: [
  {
    name: acceptProposalOgfayWGhwxMeta?.name ?? "widget-proposal-acceptProposal___sk",
    path: acceptProposalOgfayWGhwxMeta?.path ?? "acceptProposal",
    meta: acceptProposalOgfayWGhwxMeta || {},
    alias: acceptProposalOgfayWGhwxMeta?.alias || [],
    redirect: acceptProposalOgfayWGhwxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/acceptProposal.vue").then(m => m.default || m)
  },
  {
    name: indexLL8b2s6qwWMeta?.name ?? "widget-proposal___sk",
    path: indexLL8b2s6qwWMeta?.path ?? "",
    meta: indexLL8b2s6qwWMeta || {},
    alias: indexLL8b2s6qwWMeta?.alias || [],
    redirect: indexLL8b2s6qwWMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/index.vue").then(m => m.default || m)
  },
  {
    name: proposaliMkQsjSeIVMeta?.name ?? "widget-proposal-proposal___sk",
    path: proposaliMkQsjSeIVMeta?.path ?? "proposal",
    meta: proposaliMkQsjSeIVMeta || {},
    alias: proposaliMkQsjSeIVMeta?.alias || [],
    redirect: proposaliMkQsjSeIVMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/proposal.vue").then(m => m.default || m)
  },
  {
    name: refuseProposalPdWMiRiiEJMeta?.name ?? "widget-proposal-refuseProposal___sk",
    path: refuseProposalPdWMiRiiEJMeta?.path ?? "refuseProposal",
    meta: refuseProposalPdWMiRiiEJMeta || {},
    alias: refuseProposalPdWMiRiiEJMeta?.alias || [],
    redirect: refuseProposalPdWMiRiiEJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/refuseProposal.vue").then(m => m.default || m)
  }
],
    name: proposalI8TsL9l3kFMeta?.name ?? undefined,
    meta: proposalI8TsL9l3kFMeta || {},
    alias: proposalI8TsL9l3kFMeta?.alias || [],
    redirect: proposalI8TsL9l3kFMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal.vue").then(m => m.default || m)
  }
],
    meta: widget8U5j5NGATxMeta || {},
    alias: widget8U5j5NGATxMeta?.alias || [],
    redirect: widget8U5j5NGATxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget8U5j5NGATxMeta?.name ?? "widget___pl",
    path: widget8U5j5NGATxMeta?.path ?? "/pl/widget",
    children: [
  {
    path: baasSimManagementLitem4GahHmMsdMeta?.path ?? "baasSimManagementLite",
    children: [
  {
    name: indexFngy3HRLfAMeta?.name ?? "widget-baasSimManagementLite___pl",
    path: indexFngy3HRLfAMeta?.path ?? "",
    meta: indexFngy3HRLfAMeta || {},
    alias: indexFngy3HRLfAMeta?.alias || [],
    redirect: indexFngy3HRLfAMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/index.vue").then(m => m.default || m)
  },
  {
    name: loginfKMeZLSXzxMeta?.name ?? "widget-baasSimManagementLite-login___pl",
    path: loginfKMeZLSXzxMeta?.path ?? "login",
    meta: loginfKMeZLSXzxMeta || {},
    alias: loginfKMeZLSXzxMeta?.alias || [],
    redirect: loginfKMeZLSXzxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/login.vue").then(m => m.default || m)
  },
  {
    name: loginByTyper4JWDnvkfQMeta?.name ?? "widget-baasSimManagementLite-loginByType___pl",
    path: loginByTyper4JWDnvkfQMeta?.path ?? "loginByType",
    meta: loginByTyper4JWDnvkfQMeta || {},
    alias: loginByTyper4JWDnvkfQMeta?.alias || [],
    redirect: loginByTyper4JWDnvkfQMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/loginByType.vue").then(m => m.default || m)
  },
  {
    name: orderV2Y4ZTDGl4Meta?.name ?? "widget-baasSimManagementLite-order___pl",
    path: orderV2Y4ZTDGl4Meta?.path ?? "order",
    meta: orderV2Y4ZTDGl4Meta || {},
    alias: orderV2Y4ZTDGl4Meta?.alias || [],
    redirect: orderV2Y4ZTDGl4Meta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/order.vue").then(m => m.default || m)
  },
  {
    name: sim0r88wuq17kMeta?.name ?? "widget-baasSimManagementLite-sim___pl",
    path: sim0r88wuq17kMeta?.path ?? "sim",
    meta: sim0r88wuq17kMeta || {},
    alias: sim0r88wuq17kMeta?.alias || [],
    redirect: sim0r88wuq17kMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite/sim.vue").then(m => m.default || m)
  }
],
    name: baasSimManagementLitem4GahHmMsdMeta?.name ?? undefined,
    meta: baasSimManagementLitem4GahHmMsdMeta || {},
    alias: baasSimManagementLitem4GahHmMsdMeta?.alias || [],
    redirect: baasSimManagementLitem4GahHmMsdMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/baasSimManagementLite.vue").then(m => m.default || m)
  },
  {
    path: proposalI8TsL9l3kFMeta?.path ?? "proposal",
    children: [
  {
    name: acceptProposalOgfayWGhwxMeta?.name ?? "widget-proposal-acceptProposal___pl",
    path: acceptProposalOgfayWGhwxMeta?.path ?? "acceptProposal",
    meta: acceptProposalOgfayWGhwxMeta || {},
    alias: acceptProposalOgfayWGhwxMeta?.alias || [],
    redirect: acceptProposalOgfayWGhwxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/acceptProposal.vue").then(m => m.default || m)
  },
  {
    name: indexLL8b2s6qwWMeta?.name ?? "widget-proposal___pl",
    path: indexLL8b2s6qwWMeta?.path ?? "",
    meta: indexLL8b2s6qwWMeta || {},
    alias: indexLL8b2s6qwWMeta?.alias || [],
    redirect: indexLL8b2s6qwWMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/index.vue").then(m => m.default || m)
  },
  {
    name: proposaliMkQsjSeIVMeta?.name ?? "widget-proposal-proposal___pl",
    path: proposaliMkQsjSeIVMeta?.path ?? "proposal",
    meta: proposaliMkQsjSeIVMeta || {},
    alias: proposaliMkQsjSeIVMeta?.alias || [],
    redirect: proposaliMkQsjSeIVMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/proposal.vue").then(m => m.default || m)
  },
  {
    name: refuseProposalPdWMiRiiEJMeta?.name ?? "widget-proposal-refuseProposal___pl",
    path: refuseProposalPdWMiRiiEJMeta?.path ?? "refuseProposal",
    meta: refuseProposalPdWMiRiiEJMeta || {},
    alias: refuseProposalPdWMiRiiEJMeta?.alias || [],
    redirect: refuseProposalPdWMiRiiEJMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal/refuseProposal.vue").then(m => m.default || m)
  }
],
    name: proposalI8TsL9l3kFMeta?.name ?? undefined,
    meta: proposalI8TsL9l3kFMeta || {},
    alias: proposalI8TsL9l3kFMeta?.alias || [],
    redirect: proposalI8TsL9l3kFMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget/proposal.vue").then(m => m.default || m)
  }
],
    meta: widget8U5j5NGATxMeta || {},
    alias: widget8U5j5NGATxMeta?.alias || [],
    redirect: widget8U5j5NGATxMeta?.redirect || undefined,
    component: () => import("/opt/mc/app/pages/widget.vue").then(m => m.default || m)
  }
]