import SimAuthHttpFactory from '../simAuthFactory'

class SimModule extends SimAuthHttpFactory {
  private RESOURCE = '/sim'

  async getSimLogin(
    partnerId: number,
    hashId: string,
    authorizationCode: string | undefined
  ): Promise<any> {
    return await this.call<object>('POST', `${this.RESOURCE}/login`, {
      partnerId: partnerId,
      hashId: hashId,
      authorizationCode: authorizationCode
    })
  }

  async getSimLoginByType(
    partnerId: number,
    type: string,
    identificationCode: string
  ): Promise<any> {
    return await this.call<object>('POST', `${this.RESOURCE}/login/by-type`, {
      partnerId: partnerId,
      type: type,
      identificationCode: identificationCode
    })
  }

  async checkSimLogin(hashId: string): Promise<any> {
    return await this.call<object>('POST', `${this.RESOURCE}/check`, {
      hashId: hashId
    })
  }

  async getSim(): Promise<any> {
    return await this.call<object>('GET', `${this.RESOURCE}`)
  }

  async getSimAvailablePackages(): Promise<any> {
    return await this.call<object>('GET', `${this.RESOURCE}/packages/available`)
  }

  async getCountUnprocessedOrdersOnSim(): Promise<any> {
    return await this.call<object>(
      'GET',
      `${this.RESOURCE}/packages/order/unprocessed-count`
    )
  }

  async orderSimPackage(
    externalPackagePricelistId: number,
    email: string,
    msisdn: string | undefined,
    payer: object | undefined,
    backUrl: string
  ): Promise<any> {
    return await this.call<object>('POST', `${this.RESOURCE}/packages/order`, {
      externalPackagePricelistId: externalPackagePricelistId,
      email: email,
      msisdn: msisdn,
      payer: payer,
      backUrl: backUrl
    })
  }

  async getSimOrder(uuid: string): Promise<any> {
    return await this.call<object>(
      'GET',
      `${this.RESOURCE}/packages/order/${uuid}`
    )
  }

  async proceedOrderNextAction(uuid: string): Promise<any> {
    return await this.call<object>(
      'POST',
      `${this.RESOURCE}/packages/order/proceed-next-action`,
      {
        uuid
      }
    )
  }

  async downloadSimOrderAttachment(uuid: string): Promise<any> {
    return await this.callRaw(
      'GET',
      `${this.RESOURCE}/packages/order/${uuid}/download/pdf`
    )
  }

  async getTermsAndConditions(): Promise<any> {
    return await this.call<object>(
      'GET',
      `${this.RESOURCE}/terms-and-conditions`
    )
  }

  async downloadTermAndConditionAttachment(
    tacId: number,
    attchId: number
  ): Promise<any> {
    return await this.callRaw(
      'GET',
      `${this.RESOURCE}/terms-and-conditions/${tacId}/attachments/${attchId}`
    )
  }
}

export default SimModule
