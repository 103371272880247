{
  "form": {
    "required": "{name} je povinný údaj",
    "incomplete": "Omlouváme se, ale ne všechna pole jsou vyplněna správně.",
    "invalidPhoneNumber": "Neplatné telefonní číslo",
    "phonePlaceholder": "Najít mezinárodní předvolbu"
  },
  "orderFlow": {
    "error": {
      "description": "Něco se stalo. Vaše objednávka nebyla nedokončena. Prosím, zkuste to znovu."
    },
    "confirmPayment": {
      "description": "Během chvíle budete přesměrovány na platební bránu.",
      "redirectToGateway": "Přesměrovat na platební bránu"
    },
    "complete": {
      "description": "Vaše objednávka byla úspěšně dokončena.",
      "receiptIsReadyToDownload": "Vaše doklad je připraven a můžete si ho stáhnout do svého zařízení."
    },
    "showPaymentFailure": {
      "description": "Vaše platba nebyla úspěšně dokončena."
    },
    "waitForPayment": {
      "description": "Čekáme na výsledek Vaší platby."
    },
    "waitForReceipt": {
      "description": "Generujeme účtenku. Vydržte chvíli."
    },
    "timeout": {
      "description": "Byl překročen časový limit. Zašleteme Vám notifikaci o výsledku."
    }
  },
  "cancel": "Zrušit",
  "no": "Ne",
  "yes": "Ano",
  "order": "Objednat",
  "emailAddress": "E-mailová adresa",
  "phoneNumber": "Telefonní číslo",
  "somethingHappenedTryItAgain": "Něco se stalo. Prosím, zkuste to znovu.",
  "goBack": "Vrátit se zpět",
  "download": "Stáhnout",
  "noMatches": "Žádná shoda",
  "firstName": "Křestní jméno",
  "lastName": "Příjmení",
  "companyName": "Název firmy",
  "regNum": "IČO",
  "vat": "DIČ",
  "street": "Ulice",
  "houseNum": "Číslo domu",
  "city": "Město",
  "zip": "ZIP",
  "country": "Země",
  "accept": "Příjmout",
  "refuse": "Odmítnout"
}
