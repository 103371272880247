{
  "form": {
    "required": "{name} ist erforderlich",
    "incomplete": "Leider sind nicht alle Felder korrekt ausgefüllt.",
    "invalidPhoneNumber": "Ungültige Rufnummer",
    "phonePlaceholder": "Ländervorwahlen suchen"
  },
  "orderFlow": {
    "error": {
      "description": "Es ist etwas passiert. Ihre Bestellung war unvollständig. Bitte versuchen Sie es erneut."
    },
    "confirmPayment": {
      "description": "Sie werden in Kürze zum Zahlungsportal weitergeleitet.",
      "redirectToGateway": "Weiterleitung zum Zahlungs-Gateway"
    },
    "complete": {
      "description": "Ihre Bestellung wurde erfolgreich abgeschlossen.",
      "receiptIsReadyToDownload": "Ihre Quittung ist fertig und Sie können sie auf Ihr Gerät herunterladen."
    },
    "showPaymentFailure": {
      "description": "Ihre Zahlung wurde nicht erfolgreich abgeschlossen."
    },
    "waitForPayment": {
      "description": "Wir warten auf das Ergebnis Ihrer Zahlung."
    },
    "waitForReceipt": {
      "description": "Wir erstellen eine Quittung. Warten Sie eine Minute."
    },
    "timeout": {
      "description": "Das Zeitlimit wurde überschritten. Wir werden Sie über das Ergebnis benachrichtigen."
    }
  },
  "cancel": "Abbrechen",
  "no": "Nein",
  "yes": "Ja",
  "order": "Bestellung",
  "emailAddress": "E-Mail Adresse",
  "phoneNumber": "Telefonnummer",
  "somethingHappenedTryItAgain": "Es ist etwas passiert. Bitte versuchen Sie es erneut.",
  "goBack": "Zurückgehen",
  "download": "Herunterladen",
  "noMatches": "Keine Treffer",
  "firstName": "Vorname",
  "lastName": "Nachname",
  "companyName": "Firmenname",
  "regNum": "Registrierung (HRA, o. äh.)",
  "vat": "USt-ID (DE xxxxxxxxx)",
  "street": "Straße",
  "houseNum": "Hausnummer",
  "city": "Ort",
  "zip": "PLZ",
  "country": "Land",
  "accept": "Akzeptieren",
  "refuse": "Ablehnen"
}
