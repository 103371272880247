{
  "form": {
    "required": "{name} jest wymagane",
    "incomplete": "Przepraszamy, nie wszystkie pola są wypełnione poprawnie.",
    "invalidPhoneNumber": "Nieważny numer telefonu",
    "phonePlaceholder": "Wyszukaj kody połączeń do kraju"
  },
  "orderFlow": {
    "error": {
      "description": "Coś się stało. Twoje zamówienie było niekompletne. Proszę spróbować ponownie."
    },
    "confirmPayment": {
      "description": "Za chwilę zostaniesz przekierowany do bramki płatności.",
      "redirectToGateway": "Przekierowanie do bramki płatności"
    },
    "complete": {
      "description": "Twoje zamówienie zostało pomyślnie zrealizowane.",
      "receiptIsReadyToDownload": "Twój paragon jest gotowy i możesz go pobrać na swoje urządzenie."
    },
    "showPaymentFailure": {
      "description": "Twoja płatność nie została zakończona pomyślnie."
    },
    "waitForPayment": {
      "description": "Czekamy na wynik Twojej płatności."
    },
    "waitForReceipt": {
      "description": "Generujemy paragon. Poczekajcie chwilę."
    },
    "timeout": {
      "description": "Limit czasu został przekroczony. Wyślemy do Ciebie powiadomienie o wyniku."
    }
  },
  "cancel": "Anuluj",
  "no": "Nie",
  "yes": "Tak",
  "order": "Zamówienie",
  "emailAddress": "Adres e-mail",
  "phoneNumber": "Numer telefonu",
  "somethingHappenedTryItAgain": "Coś się stało. Proszę spróbować ponownie.",
  "goBack": "Wróć",
  "download": "Pobierz",
  "noMatches": "Brak dopasowań",
  "firstName": "Imię",
  "lastName": "Nazwisko",
  "companyName": "Company name",
  "regNum": "REGON",
  "vat": "NIP",
  "street": "Ulica",
  "houseNum": "Numer domu",
  "city": "Miasto",
  "zip": "ZIP",
  "country": "Kraj",
  "accept": "Akceptuj",
  "refuse": "Odrzucić"
}
