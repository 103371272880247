import { RemovableRef, useStorage } from '@vueuse/core'
import { $Fetch } from 'ohmyfetch'
import HttpFactory from './factory'

class AuthHttpFactory extends HttpFactory {
  private $token: RemovableRef<null>

  constructor(fetcher: $Fetch) {
    super(fetcher)
    this.$token = useStorage('token', null)
  }

  setToken(token: RemovableRef<null>) {
    this.$token = token
  }

  async call<T>(
    method: string,
    url: string,
    data?: object,
    extras = {}
  ): Promise<T> {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Content-Type', 'application/json')
    if (this.$token.value)
      requestHeaders.set('Authorization', `Bearer ${this.$token.value}`)

    const $res: T = await this.$fetch(url, {
      method,
      body: data,
      headers: requestHeaders,
      ...extras
    })

    return $res
  }
}

export default AuthHttpFactory
