export interface IWidgetRoutes {
  [key: string]: object
}

export const routes: IWidgetRoutes = {
  baasSimManagementLite: [
    {
      path: 'home',
      name: 'widget-baasSimManagementLite-home',
      component: () => import('~/pages/widget/baasSimManagementLite/index.vue')
    },
    {
      path: 'sim/login/:type',
      name: 'widget-baasSimManagementLite-loginByType',
      component: () =>
        import('~/pages/widget/baasSimManagementLite/loginByType.vue')
    },
    {
      path: 'sim/:simHashId/login',
      name: 'widget-baasSimManagementLite-login',
      component: () => import('~/pages/widget/baasSimManagementLite/login.vue')
    },
    {
      path: 'sim/:simHashId',
      name: 'widget-baasSimManagementLite-sim',
      component: () => import('~/pages/widget/baasSimManagementLite/sim.vue'),
      meta: {
        middleware: ['baas-sim-management-lite-auth']
      }
    },
    {
      path: 'sim/:simHashId/order/:uuid',
      name: 'widget-baasSimManagementLite-order',
      component: () => import('~/pages/widget/baasSimManagementLite/order.vue'),
      meta: {
        middleware: ['baas-sim-management-lite-auth']
      }
    }
  ],
  proposal: [
    {
      path: 'home',
      name: 'widget-proposal-home',
      component: () => import('~/pages/widget/proposal/index.vue')
    },
    {
      path: 'proposal/:publicId',
      name: 'widget-proposal',
      component: () => import('~/pages/widget/proposal/proposal.vue')
    },
    {
      path: 'proposal/:publicId/accept',
      name: 'widget-accept-proposal',
      component: () => import('~/pages/widget/proposal/acceptProposal.vue')
    },
    {
      path: 'proposal/:publicId/refuse',
      name: 'widget-refuse-proposal',
      component: () => import('~/pages/widget/proposal/refuseProposal.vue')
    }
  ]
}
