import AuthHttpFactory from '../authFactory'

class AuthModule extends AuthHttpFactory {
  private RESOURCE = '/customer'

  async login(credentials: object): Promise<any> {
    return await this.call<object>(
      'POST',
      `${this.RESOURCE}/login`,
      credentials
    )
  }
}

export default AuthModule
