import plugin_vue3_A0OWXRrUgq from "/opt/mc/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/mc/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/mc/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/opt/mc/app/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/opt/mc/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/mc/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/mc/app/.nuxt/formkitPlugin.mjs";
import composition_sLxaNGmlSL from "/opt/mc/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/opt/mc/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/mc/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/opt/mc/app/plugins/api.ts";
import eventBus_P6CbRuQa3W from "/opt/mc/app/plugins/eventBus.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  formkitPlugin_pZqjah0RUG,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  eventBus_P6CbRuQa3W
]