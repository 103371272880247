{
  "form": {
    "required": "{name} je vyžadováno",
    "incomplete": "Ospravedlňujeme sa, ale nie všetky polia sú vyplnené správne.",
    "invalidPhoneNumber": "Neplatné telefónne číslo",
    "phonePlaceholder": "Vyhľadanie predvoľby"
  },
  "orderFlow": {
    "error": {
      "description": "Niečo sa stalo. Vaša objednávka bola neúplná. Skúste to prosím znova."
    },
    "confirmPayment": {
      "description": "O chvíľu budete presmerovaní na platobnú bránu.",
      "redirectToGateway": "Presmerovanie na platobnú bránu"
    },
    "complete": {
      "description": "Vaša objednávka bola úspešne dokončená.",
      "receiptIsReadyToDownload": "Váš doklad je pripravený a môžete si ho stiahnuť do svojho zariadenia."
    },
    "showPaymentFailure": {
      "description": "Vaša platba nebola úspešne dokončená."
    },
    "waitForPayment": {
      "description": "Čakáme na výsledok vašej platby."
    },
    "waitForReceipt": {
      "description": "Vygenerujeme potvrdenie o prijatí. Počkajte chvíľu."
    },
    "timeout": {
      "description": "Časový limit bol prekročený. Pošleme vám oznámenie o výsledku."
    }
  },
  "cancel": "Zrušiť",
  "no": "Nie",
  "yes": "Áno",
  "order": "Objednávka",
  "emailAddress": "E-mailová adresa",
  "phoneNumber": "Telefónne číslo",
  "somethingHappenedTryItAgain": "Niečo sa stalo. Skúste to prosím znova.",
  "goBack": "Vráťte sa späť",
  "download": "Stiahnite si",
  "noMatches": "Žiadna zhoda",
  "firstName": "Krstné meno",
  "lastName": "Priezvisko",
  "companyName": "Company name",
  "regNum": "IČO",
  "vat": "DIČ",
  "street": "Ulica",
  "houseNum": "Číslo domu",
  "city": "Mesto",
  "zip": "ZIP",
  "country": "Krajina",
  "accept": "Prijať",
  "refuse": "Odmietnuť"
}
