import type { NavigateToOptions } from 'nuxt/dist/app/composables/router'
import type { RouteLocationRaw } from 'vue-router'

export const useWidget = () => {
  const route = useRoute()

  const getWidgetHash = () => {
    return route.params.hash
  }

  const linkTo = (to: string) => {
    return `/widget/${getWidgetHash()}/${to}`
  }

  const redirectTo = async (
    to: RouteLocationRaw | null | undefined,
    options?: object
  ) => {
    return await navigateTo({
      path: `/widget/${getWidgetHash()}/${to}`,
      ...options
    })
  }

  return {
    getWidgetHash,
    linkTo,
    redirectTo
  }
}
