import { $fetch, FetchOptions } from 'ohmyfetch'
import { defineNuxtPlugin } from '#app'
import AuthModule from '~~/repository/modules/auth'
import PublicModule from '~~/repository/modules/public'
import SimModule from '~~/repository/modules/sim'

interface IApiInstance {
  auth: AuthModule
  public: PublicModule
  sim: SimModule
}

export default defineNuxtPlugin(nuxtApp => {
  const fetchOptions: FetchOptions = {
    baseURL: nuxtApp.$config.apiBaseUrl
  }

  /** create a new instance of $fetcher with custom option */
  const apiFetcher = $fetch.create(fetchOptions)

  /** an object containing all repositories we need to expose */
  const modules: IApiInstance = {
    auth: new AuthModule(apiFetcher),
    public: new PublicModule(apiFetcher),
    sim: new SimModule(apiFetcher, nuxtApp.$i18n)
  }

  return {
    provide: {
      api: modules
    }
  }
})
