{
  "form": {
    "required": "{name} is required",
    "incomplete": "Sorry, not all fields are filled out correctly.",
    "invalidPhoneNumber": "Invalid phone number"
  },
  "orderFlow": {
    "error": {
      "description": "Something happened. Your order was incomplete. Please try again."
    },
    "confirmPayment": {
      "description": "You will be redirected to the payment gateway in a moment.",
      "redirectToGateway": "Redirect to payment gateway"
    },
    "complete": {
      "description": "Your order has been successfully completed.",
      "receiptIsReadyToDownload": "Your receipt is ready and you can download it to your device."
    },
    "showPaymentFailure": {
      "description": "Your payment was not completed successfully."
    },
    "waitForPayment": {
      "description": "We are waiting for the result of your payment."
    },
    "waitForReceipt": {
      "description": "We generate a receipt. Wait a minute."
    },
    "timeout": {
      "description": "The time limit has been exceeded. We will send you a notification of the result."
    }
  },
  "cancel": "Cancel",
  "no": "No",
  "yes": "Yes",
  "order": "Order",
  "emailAddress": "E-mail address",
  "phoneNumber": "Phone number",
  "somethingHappenedTryItAgain": "Something happened. Please try again.",
  "goBack": "Go back",
  "download": "Download",
  "firstName": "First name",
  "lastName": "Last name",
  "companyName": "Company name",
  "regNum": "Registration No.",
  "vat": "TAX ID",
  "street": "Street",
  "houseNum": "House Number",
  "city": "City",
  "zip": "Zip Code",
  "country": "Země",
  "accept": "Accept",
  "refuse": "Refuse"
}
