import { RemovableRef, useStorage } from '@vueuse/core'
import { $Fetch, FetchResponse } from 'ohmyfetch'
import HttpFactory from './factory'

class SimAuthHttpFactory extends HttpFactory {
  private $token: RemovableRef<null>
  private $i18n: any

  constructor(fetcher: $Fetch, i18n: any) {
    super(fetcher)
    this.$i18n = i18n
    this.$token = useStorage('sim-token', null)
  }

  setToken(token: RemovableRef<null>) {
    this.$token = token
  }

  async call<T>(
    method: string,
    url: string,
    data?: object,
    extras = {}
  ): Promise<T> {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Content-Type', 'application/json')
    requestHeaders.set('Accept-Language', this.$i18n.locale.value)
    if (this.$token.value)
      requestHeaders.set('Authorization', `Bearer ${this.$token.value}`)

    const $res: T = await this.$fetch(url, {
      method,
      body: data,
      headers: requestHeaders,
      ...extras
    })

    return $res
  }

  async callRaw(
    method: string,
    url: string,
    data?: object,
    extras = {}
  ): Promise<FetchResponse<any>> {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Content-Type', 'application/json')
    requestHeaders.set('Accept-Language', this.$i18n.locale.value)
    if (this.$token.value)
      requestHeaders.set('Authorization', `Bearer ${this.$token.value}`)

    const $res: FetchResponse<any> = await this.$fetch.raw(url, {
      method,
      body: data,
      headers: requestHeaders,
      ...extras
    })

    return $res
  }
}

export default SimAuthHttpFactory
