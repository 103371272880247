import HttpFactory from '../factory'

class PublicModule extends HttpFactory {
  private RESOURCE = '/public'

  async getWidget(hash: string): Promise<any> {
    return await this.call<object>('GET', `${this.RESOURCE}/widget/${hash}`)
  }

  async getProposal(hash: string): Promise<any> {
    return await this.call<object>('GET', `${this.RESOURCE}/proposal/${hash}`)
  }

  async refuseProposal(hash: string, data: Object): Promise<any> {
    return await this.call<object>('POST', `${this.RESOURCE}/proposal/${hash}/refuse`, data)
  }

  async acceptProposal(hash: string, data: Object): Promise<any> {
    return await this.call<object>('POST', `${this.RESOURCE}/proposal/${hash}/accept`, data)
  }
}

export default PublicModule
