import type { RouterConfig } from '@nuxt/schema'
import WidgetPage from '~/pages/widget.vue'

export default <RouterConfig>{
  routes: _routes => {
    return [
      {
        path: '/',
        name: 'home',
        component: () => import('~/pages/index.vue')
      },
      {
        path: '/widget/:hash/:path(.*)?',
        name: 'widget-path',
        component: WidgetPage
      }
    ]
  }
}
